import React from 'react';
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import FullProductCard from "../../components/fullProductCard";
import { ContentSafeArea } from '../../components/globalComponents';
import { useStaticQuery, graphql } from 'gatsby';

import { PageTitle, ProductsGrid } from './styles';

export default function Produtos() {

  const { productsMdx } = useStaticQuery(graphql`
    query {
      productsMdx: allMdx(filter: {frontmatter: {draft: {ne: true}}}) {
        nodes {
          frontmatter {
            title
            slug
            description
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  `)

  return(
    <Layout>
    <SEO title="Produtos" />
    <ContentSafeArea>
      <PageTitle>Nossos produtos:</PageTitle>
      <ProductsGrid>
      {productsMdx.nodes.map(({frontmatter}) =>
        <FullProductCard image={frontmatter.featuredImage.childImageSharp.fluid} title={frontmatter.title} slug={frontmatter.slug} description={frontmatter.description}/>
      )}
      </ProductsGrid>
    </ContentSafeArea>
  </Layout>
  )
}
