import styled from 'styled-components';
import { Button, StyledLink } from '../globalComponents';

export const CardContainer = styled(StyledLink)`
  text-decoration: none;
  color: inherit;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  /* overflow: hidden; */
  /* border-radius: 15px;
  box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.15); */

  &:hover {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
  }

  /* height: 200px; */
`;

export const CardImageContainer = styled.div`
  height: 100%;
  max-height: 150px;
  width: 33%;
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.25);
`;

export const CardTextContainer = styled.div`
  width: 67%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.5rem;
  padding-top: 0;

  h2 {
    margin-bottom: 0;
  }

  p {
    padding: 0;
    margin: 0;
  }

  h2, p {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
`;

export const CardInfo = styled.div``;

export const KnowMoreButton = styled(Button)`
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* background: none; */
  padding: 0.2rem 0.5rem;
  margin: 0;
  font-weight: 400;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;
