import React from 'react';
import Img from 'gatsby-image';

import { CardContainer, CardImageContainer, CardTextContainer, CardInfo } from './styles';

export default function FullProductCard({image, title = "Card title", slug = "", price = "", description = ""}) {
  return (
    <CardContainer to={ "/" + slug }>
      <CardImageContainer>
        <Img
          style={{ height: "100%", width: "100%" }}
          imgStyle={{ objectFit: "cover" }}
          fluid={image}
        />
      </CardImageContainer>
      <CardTextContainer>
        <CardInfo>
          <h2>{ title }</h2>
          <p>{ description }</p>
        </CardInfo>
      </CardTextContainer>
    </CardContainer>
  )
}
