import styled from 'styled-components';

export const PageTitle = styled.h1`
  margin-top: 2rem;
`

export const ProductsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  margin-bottom: 2rem;
  align-items: flex-start;

  @media(max-width: 801px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
